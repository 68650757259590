.m-bottom {
    margin-bottom: 60px;
}

.content {
    max-width:2000px;
    margin-top:46px;
}

.container {
    max-width:800px;
}

.logo {
    display: block;
    margin: 0 auto;
    width: 200px;
}